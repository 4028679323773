.about {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 10;

  &.is-hidden {
    display: none;
  }

  &__inner {
    position: relative;
    margin-top: $gtr * 2;
    margin-right: $gtr * 2;
    padding: ($gtr * 4) ($gtr * 2);
    background: $light-blue;
    color: $darker-green;
    min-height: calc(100% - #{$gtr * 4});

    @include media($m-screen-up) {
      margin-top: $gtr * 4;
      margin-right: $gtr * 4;
      padding: $gtr * 4;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: $max-width;
    margin: 0 auto;
  }

  &__label {
    position: relative;
    grid-column: auto / span 1;

    .label {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(90deg) translate(($gtr/2), -50%);
      transform-origin: left top;

      @include media($m-screen-up) {
        left: 50%;
      }

      span {
        display: inline-block;
        transform: rotate(180deg);
      }
    }
  }

  &__bio {
    a {
      @include linkUnderline();
    }
  }

  &__bio,
  &__clients {
    grid-column: auto / span 11;

    @include media($m-screen-up) {
      grid-column: auto / span 5;
    }
  }

  &__close {
    position: absolute;
    top: $gtr;
    right: $gtr;

    @include media($m-screen-up) {
      top: $gtr * 2;
      right: $gtr * 2;
    }
  }
}
