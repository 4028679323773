.btn {
  @include btnReset;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: $yellow 1px solid;
    outline-offset: rem(1);
  }

  &--iris {
    position: relative;
    color: $light-blue;
    background-image:
      linear-gradient($light-blue, $light-blue),
      linear-gradient($light-blue, $light-blue);
    background-repeat: no-repeat;
    background-size: 0 rem(1);
    background-position: left top, right bottom;
    transition:
      background 0.2s 0.2s ease-in-out,
      opacity 0.1s ease-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: rem(1);
      height: 100%;
      background-repeat: no-repeat;
      background-image: linear-gradient($light-blue, $light-blue);
      transition: background 0.2s ease-in-out;
      background-size: rem(1) 0;
    }

    &::before {
      left: 0;
      background-position: left bottom;
    }

    &::after {
      right: 0;
      transition-delay: 0.4s;
    }

    &:hover {
      background-size: 100% rem(1);

      &::before,
      &::after {
        background-size: rem(1) 100%;
      }
    }

    &:active {
      opacity: 0.5;
    }
  }

  &--iris-text {
    padding: rem(4) rem(4) rem(4) rem(6);
    transition:
      background 0.2s 0.2s ease-in-out,
      letter-spacing 0.4s ease-out,
      padding 0.4s ease-out,
      opacity 0.1s ease-out;

    &:hover {
      letter-spacing: rem(5);
      padding-left: rem(8);
    }
  }

  &--iris-yellow {
    padding: rem(5);
    background-image:
      linear-gradient($dark-green, $dark-green),
      linear-gradient($dark-green, $dark-green);

    &::before,
    &::after {
      background-image: linear-gradient($dark-green, $dark-green);
    }
  }
}
