.text {
  font-size: rem(24);
  line-height: 1.2;

  &--dark-green {
    color: $dark-green;
  }

  &--light-green {
    color: $light-green;
  }

  &--large {
    font-size: rem(28);

    @include media($m-screen-up) {
      font-size: rem(36);
      letter-spacing: rem(-0.6);
    }
  }
}
