* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-bottom: $gtr;
}

strong {
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: rem(36);
  letter-spacing: rem(-0.6);

  @include media($m-screen-up) {
    font-size: rem(72);
    letter-spacing: rem(-1.2);
  }
}

h2 {
  font-size: rem(28);
  letter-spacing: rem(-0.3);

  @include media($m-screen-up) {
    font-size: rem(36);
    letter-spacing: rem(-0.6);
  }
}

h3 {
  font-size: rem(24);
  letter-spacing: rem(-0.25);

  @include media($m-screen-up) {
    font-size: rem(32);
    letter-spacing: rem(-0.5);
  }
}

h4 {
  font-size: rem(16);
  letter-spacing: rem(-0.2);

  @include media($m-screen-up) {
    font-size: rem(24);
  letter-spacing: rem(-0.4);
  }
}
