*,
:before,
:after {
  box-sizing: border-box;
  margin: 0;
}

html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  font-size: rem(18);
  line-height: 1;
  color: $light-blue;
  background: $darker-green;
  font-family: $ff;

  &.modal-open {
    overflow: hidden;
  }
}

main {
  position: relative;
  z-index: 1;
  background: $dark-green;
  box-shadow: 0 0 8px 0 transparentize(black, 0.7);
}

.container {
  max-width: $max-width;
  margin: 0 auto;
  padding-left: $gtr;
  padding-right: $gtr;
  overflow-x: hidden;
}

a {
  color: currentColor;

  &:focus-visible {
    outline: 1px dotted currentColor;
    outline-offset: rem(3);
  }
}

img {
  width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}
