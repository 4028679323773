.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $darker-green;

  display: flex;
  align-items: center;
  min-height: 50vh;

  &__inner {
    @extend .container;
    width: 100%;
    padding-top: ($gtr * 2);
    padding-bottom: ($gtr * 2);
    font-size: rem(16);

    @include media($m-screen-up) {
      padding-top: ($gtr * 4);
      padding-bottom: ($gtr * 4);
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  h4 {
    @extend h1;
    margin-bottom: $gtr / 2;

    @include media($m-screen-up) {
      margin-bottom: 0;
    }
  }

  &__links {
    @include listReset;
    margin-bottom: $gtr;

    @include media($m-screen-up) {
      margin-bottom: 0;
    }

    li {
      display: inline-block;

      &::after {
        content: "/";
        color: $yellow;
        margin-left: $gtr / 2;
        margin-right: $gtr / 2;
      }

      &:last-child::after {
        content: "";
        margin: 0;
      }
    }
  }
}
