.header {
  &__inner {
    @extend .container;
    padding-top: ($gtr * 2);
    padding-bottom: ($gtr * 2);
  }

  .home & {
    display: none;
  }

  a {
    text-decoration: none;
  }
}
