// :root {
//   --vh: 100vh;
// }

.intro {
  min-height: 100vh;

  @include media($m-screen-up) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto fit-content(100%);
  }

  &__canvas {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media($m-screen-up) {
      grid-column: auto / span 12;
      grid-column: 7 / span 6;
      height: auto;
      order: 2;
    }

    canvas {
      position: relative;
      z-index: -1;

      @include media($m-screen-up) {
        // position: fixed;
        // top: 0;
      }
    }
  }

  &__text {
    // grid-column: auto / span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media($m-screen-up) {
      grid-column: auto / span 5;
      order: 1;
    }

    h1 {
      margin-bottom: $gtr / 2;
    }

    p {
      margin-bottom: $gtr / 2;
    }

    a {
      text-decoration: none;
    }
  }

  &__label {
    span {
      display: block;

      @include media($m-screen-up) {
        display: inline;
      }
    }
  }

  &__btn {
    margin-left: rem(5);
  }

  &__highlights {
    display: flex;
    align-items: center;
    padding: $gtr 0;
    transform: translateX(-$gtr + rem(2));

    @include media($m-screen-up) {
      padding: ($gtr * 2) 0;
      grid-column: auto / span 12;
      order: 3;
    }

    .icon {
      margin-right: $gtr;
    }
  }
}
