.list {
  list-style-type: none;
  padding: 0;
  font-size: rem(20);
  line-height: 1.4;

  &__item {
    display: inline-flex;
    align-items: center;

    &::after {
      content: '\2219';
      color: $yellow;
      margin: 0 $gtr / 2;
    }

    &:last-child::after {
      content: '';
      margin: 0;
    }
  }
}
