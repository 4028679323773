.icon {
  display: inline-block;
  width: rem(30);
  height: rem(30);
}

.icon--plus {
  padding: rem(7.5);

  line {
    stroke: $light-blue;
  }
}

.icon--close {
  padding: rem(5);

  line {
    stroke: $dark-green;
  }
}

.icon--eye {
  .eye-all {
    transition: transform 0.2s ease-in-out;
  }

  .eyelashes {
    opacity: 0;
    transition: opacity 0.2s ease-in;

    line {
      stroke-dasharray: 8;
      stroke-dashoffset: 8;
      transition: all 0.2s ease-out;
    }

    .eyelash-1 { transition-delay: 0.1s; }
    .eyelash-2 { transition-delay: 0.2s; }
    .eyelash-3 { transition-delay: 0.3s; }
  }
}

.card:hover {
  .eye-all {
    transform: translateY(rem(5));
  }

  .eyelashes {
    opacity: 1;

    line {
      stroke-dashoffset: 0;
    }
  }
}
