$stop1: #AADDD6;
$stop2: #B8DFCA;
$stop3: #C7E1BE;
$stop4: #D5E2B3;
$stop5: #E3E4A6;
$stop6: #F0E59A;

.zig {
  display: grid;
  grid-template-columns: repeat(16, 1fr);

  @include media($m-screen-up) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.zig-end {
  grid-column: span 13;
  display: grid;
  border-bottom: $border-primary;
  width: 0;

  @include media($m-screen-up) {
    grid-column: span 9;
    grid-template-columns: repeat(9, 1fr);
  }

  &__label {
    display: none;

    @include media($m-screen-up) {
      display: flex;
      align-items: flex-end;
      margin-bottom: $space;
      padding-left: $space * 2;
      border-left: rem(4) solid transparent;
      grid-column: auto / span 4;
      opacity: 0;
      transform: translateX(-$space * 2);
    }

    &--second {
      grid-column-start: 5;
      border: none;
    }
  }
}

.zag {
  grid-column: auto / span 1;
  position: relative;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &__left,
  &__left-wrap,
  &__bottom,
  &__bottom-wrap {
    position: absolute;
    left: 0;
  }

  &__left-wrap {
    top: 0;
    width: $default-border-width;
    height: 100%;
    overflow: hidden;
  }

  &__left {
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient($stop1, $stop2);
    transform: translateY(-101%);
  }

  &__bottom-wrap {
    bottom: 0;
    height: $default-border-width;
    width: 100%;
    margin-left: $default-border-width;
    overflow: hidden;
  }

  &__bottom {
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, $stop2, $stop3);
    transform: translateX(-101%);
  }

  &--2 {
    grid-column-start: 2;

    .zag__left {
      background-image: linear-gradient($stop3, $stop4);
    }

    .zag__bottom {
      background-image: linear-gradient(90deg, $stop4, $stop5);
    }
  }

  &--3 {
    grid-column-start: 3;

    .zag__left {
      background-image: linear-gradient($stop5, $stop6);
    }

    .zag__bottom {
      background-image: linear-gradient(90deg, $stop6, $yellow);
    }
  }
}
