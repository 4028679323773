.label {
  text-transform: uppercase;
  font-size: rem(14);
  letter-spacing: rem(1.5);
  line-height: 1.2;

  @include media($m-screen-up) {
    font-size: rem(16);
    letter-spacing: rem(3);
  }

  &--link {
    padding-bottom: rem(3);
    text-decoration: none;
    text-shadow: 1px 0 0 $dark-green, 0 1px 0 $dark-green, -1px 0 0 $dark-green;
    background-repeat: no-repeat;
    background-image:
      linear-gradient($yellow, $yellow),
      linear-gradient($light-blue, $light-blue);
    background-size: 100% 1px, 0 1px;
    background-position: right 100%, left 100%;
    transition: background-size 0.2s ease-in-out;

    &:hover {
      background-size: 0 1px, 100% 1px;
    }
  }
}
