.work {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  padding-bottom: ($gtr * 6);

  @include media($m-screen-up) {
    grid-template-columns: repeat(12, 1fr);
  }

  &__list {
    @include listReset;
    grid-column: 4 / span 13;

    @include media($m-screen-up) {
      grid-column: 4 / span 9;
    }
  }

  &__item {
    position: relative;
    opacity: 0;
    transform: translateY(-$space * 2);
    z-index: 1;

    &:nth-child(1) {
      transform: translateY(0);
      z-index: 2;
    }
  }
}
